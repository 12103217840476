import React from "react";
import "../../style-desktop.css";

export const ConfirmationDesktop = () => {
  return (
    <div className="lambert">
      <img className="frame" src="/img/frame.svg" />
      <div className="div">
        <div className="frame-2">
          <div className="group">
            {/* <h1 className="text-wrapper">Lambert</h1> */}
            <div className="overlap-group-wrapper">
              <div className="overlap-group">
                <a href="/">
                  <img src="/img/logo.svg" />
                </a>
              </div>
            </div>
          </div>
          <p className="meet-lambert-your">
            <span className="span">Success</span>
          </p>
          <p className="lambert-is-an-office">
            Your installation is complete ✅
          </p>
        </div>
      </div>
      <div className="frame-3">
        <img className="frame-6" src="/img/frame-6.svg" />
        <div className="frame-multiple-features">
          <div className="text-wrapper-2">Multiple features</div>
          <div className="frame-5">
            <p className="track-debts-between">
              <span className="text-wrapper-3">
                🏦&nbsp;&nbsp; Track debts between colleagues
              </span>
            </p>
            <div className="span-wrapper">
              <span className="text-wrapper-3">🤐&nbsp;&nbsp;Swear jar</span>
            </div>
            <p className="text-wrapper-3">
              🎲&nbsp;&nbsp;Create poll,&nbsp;roll, or pick random
            </p>
            <div className="span-wrapper">
              <span className="text-wrapper-3">
                🎂&nbsp;&nbsp;Birthday notifications
              </span>
            </div>
            <p className="text-wrapper-3">
              📷&nbsp;&nbsp;Search and deliver random images or videos - for fun
            </p>
            <div className="text-wrapper-3">🎁&nbsp;&nbsp;Secret Santa</div>
          </div>
        </div>
      </div>
      <div className="frame-7">
        <img className="open-debt-png" src="/img/open-debt-png.png" />
        <div className="frame-8">
          <div className="bank-header-text">
            <div className="bank-header">Settle debts</div>
            <p className="bank-text">
              Never lose track of office debts. <br />
              Open tabs, track and settle debts all within Slack.
            </p>
          </div>
          <img className="frame-9" src="/img/frame-5.svg" />
        </div>
      </div>
      <div className="frame-swear-1">
        <div className="frame-swear-2">
          <div className="swear-header-text">
            <div className="text-wrapper-2">Swear jar</div>
            <p className="pay-for-your-swears">
              Pay for your swears&nbsp;and improve the office budget.
            </p>
          </div>
          <div className="overlap-wrapper">
            <div className="overlap">
              <div className="overlap-group-2">
                <img
                  className="overlap-group-swear-jar"
                  src="/img/swear-jar.svg"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="frame-swear-3">
          <img className="stats-no-swears" src="/img/new-1.png" />
          <img className="stats-no-swears" src="/img/stats-no-swears-1.png" />
        </div>
      </div>
      <div className="frame-poll-1">
        <div className="frame-poll-2">
          <div className="poll-examples">
            <div className="overlap-group-4">
              <img className="poll" src="/img/poll2-1.png" />
              <img className="roll" src="/img/roll3-1.png" />
            </div>
          </div>
        </div>
        <div className="frame-poll-3">
          <div className="frame-poll-header-text">
            <p className="heading-settle">
              Create polls,&nbsp;roll, or pick random
            </p>
            <p className="poll-text">
              End indecision about lunch, suggest team building activities?
              <br></br>
              Resolve disagreement once and for all.
            </p>
          </div>
          <div className="frame-18">
            <div className="overlap-3">
              <div className="overlap-group-5">
                <img src="/img/pick-roll.svg" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="frame-santa-1">
        <div className="frame-santa-2">
          <div className="santa-header-text">
            <div className="text-wrapper-2">Secret Santa</div>
            <p className="pay-for-your-swears">
              'Tis the season to be jolly, boost the holiday spirit by
              <br /> organizing Secret 🎅 with your team. <br />
              Fa la la la la... 🎁
            </p>
          </div>
          <img className="santa-svg" src="/img/frame-2.svg" />
        </div>
        <div className="frame-20">
          <img className="secret-santa" src="/img/answer-1.png" />
        </div>
      </div>
      <div className="div">
        <p className="text-wrapper-9">Add Lambert to your workspace now!</p>
      </div>
      <div className="frame-21">
        <div className="section">
          <div className="frame-22">
            <div className="text-wrapper-9">FAQ</div>
            <div className="frame-23">
              <div className="frame-24">
                <div className="frame-14">
                  <p className="text-wrapper-10">
                    How do I install Lambert on my Slack workspace?
                  </p>
                  <p className="p">
                    It&#39;s really simple, just click&nbsp; 
                    <a href="https://prod.lambertbot.com/auth" className="text-wrapper-12">
                      Add to slack
                    </a> and follow
                    the instructions.
                  </p>
                </div>
                <div className="frame-14">
                  <p className="text-wrapper-10">
                    Where can I get help if this app doesn’t work?
                  </p>
                  <p className="p-2">
                    <span className="text-wrapper-11">Reach out to us at </span>
                    <a
                      href="mailto:lambert@lambdaworks.io"
                      className="text-wrapper-12"
                    >
                      lambert@lambdaworks.io
                    </a>
                    <span className="text-wrapper-11">
                      {" "}
                      and we&#39;ll help you out as soon as we can.
                    </span>
                  </p>
                </div>
              </div>
              <div className="frame-25">
                <div className="frame-21">
                  <div className="frame-26">
                    <div className="text-wrapper-10">
                      Can I send suggestions?
                    </div>
                    <p className="p-2">
                      <span className="text-wrapper-11">
                        For sure, we’d love to hear what you have to say. Just
                        hit us up at
                      </span>
                      <a
                        href="mailto:lambert@lambdaworks.io"
                        className="text-wrapper-12"
                      >
                        {" "}
                        lambert@lambdaworks.io
                      </a>
                    </p>
                  </div>
                </div>
                <img className="frame-28" src="/img/frame-1.svg" />
              </div>
            </div>
          </div>
        </div>
        <div className="frame-29">
          <p className="made-with-by">
            <span className="text-wrapper-11">Made with 🧡&nbsp;&nbsp;by </span>
            <a href="https://lambdaworks.io" className="text-wrapper-12">
              LambdaWorks.io
            </a>
          </p>
          <div className="div-align-center">
            <p className="link-support">
              <a href="/support" className="link-support">
                Support
              </a>{" "}
              /{" "}
              <a href="/policy" className="link-support">
              Terms and Conditions & Privacy Policy
              </a>
            </p>
          </div>
        </div>
      </div>
    </div>
    // <div className="lambert">
    //   <div className="div">
    //     <div className="frame-2">
    //       <div className="group">
    //         <h1 className="text-wrapper">Success</h1>
    //         <div className="overlap-group-wrapper">
    //           <div className="overlap-group">
    //             <div className="ellipse" />
    //             <img className="vector" src="/img/vector-124.svg" />
    //             <img className="img" src="/img/vector.svg" />
    //             <img className="vector-2" src="/img/vector-123.svg" />
    //             <img className="vector-3" src="/img/vector-122.svg" />
    //             <img className="group-2" src="/img/group-45.png" />
    //             <img className="vector-4" src="/img/vector-121.svg" />
    //             <img className="group-3" src="/img/group-44.png" />
    //             <img className="group-4" src="/img/group-43.png" />
    //             <img className="group-5" src="/img/group-42.png" />
    //             <img className="vector-5" src="/img/vector-120.svg" />
    //             <img className="vector-6" src="/img/vector-119.svg" />
    //             <img className="group-6" src="/img/group-41.png" />
    //             <img className="vector-7" src="/img/vector-118.svg" />
    //             <img className="vector-8" src="/img/vector-117.svg" />
    //             <img className="vector-9" src="/img/vector-116.svg" />
    //             <img className="vector-10" src="/img/vector-115.svg" />
    //             <img className="vector-11" src="/img/vector-114.svg" />
    //             <img className="vector-12" src="/img/vector-113.svg" />
    //             <img className="vector-13" src="/img/vector-112.svg" />
    //             <img className="vector-14" src="/img/vector-111.svg" />
    //             <img className="vector-15" src="/img/vector-110.svg" />
    //             <img className="vector-16" src="/img/vector-109.svg" />
    //             <img className="vector-17" src="/img/vector-108.svg" />
    //             <img className="vector-18" src="/img/vector-107.svg" />
    //             <img className="vector-19" src="/img/vector-106.svg" />
    //             <img className="vector-20" src="/img/vector-105.svg" />
    //             <img className="vector-21" src="/img/vector-104.svg" />
    //             <img className="vector-22" src="/img/vector-103.svg" />
    //             <img className="vector-23" src="/img/vector-102.svg" />
    //             <img className="vector-24" src="/img/vector-101.svg" />
    //             <img className="vector-25" src="/img/vector-100.svg" />
    //             <img className="vector-26" src="/img/vector-99.svg" />
    //             <img className="vector-27" src="/img/vector-98.svg" />
    //             <img className="vector-28" src="/img/vector-97.svg" />
    //             <img className="vector-29" src="/img/vector-96.svg" />
    //             <img className="vector-30" src="/img/vector-95.svg" />
    //             <img className="vector-31" src="/img/vector-94.svg" />
    //             <img className="vector-32" src="/img/vector-93.svg" />
    //             <img className="vector-33" src="/img/vector-92.svg" />
    //             <img className="vector-34" src="/img/vector-91.svg" />
    //             <img className="group-7" src="/img/group-40.png" />
    //           </div>
    //         </div>
    //       </div>
    //       <p className="meet-lambert-your">
    //         <span className="span">Your installation is complete ✅ </span>
    //       </p>
    //     </div>
    //   </div>
    //   <div className="frame-21">
    //     <div className="section">
    //       <div className="frame-22">
    //         <div className="text-wrapper-9">FAQ</div>
    //         <div className="frame-23">
    //           <div className="frame-24">
    //             <div className="frame-14">
    //               <p className="text-wrapper-10">
    //                 How do I install Lambert on my Slack workspace?
    //               </p>
    //               <p className="p">
    //                 It&#39;s really simple, just &lt;Add to Slack&gt; and follow
    //                 the instructions.
    //               </p>
    //             </div>
    //             <div className="frame-14">
    //               <p className="text-wrapper-10">
    //                 Where can I get help if this app doesn’t work?
    //               </p>
    //               <p className="p-2">
    //                 <span className="text-wrapper-11">Reach out to us at </span>
    //                 <span className="text-wrapper-12">
    //                   lambert@lambdaworks.io
    //                 </span>
    //                 <span className="text-wrapper-11">
    //                   {" "}
    //                   and we&#39;ll help you out as soon as we can.
    //                 </span>
    //               </p>
    //             </div>
    //           </div>
    //           <div className="frame-25">
    //             <div className="frame-21">
    //               <div className="frame-26">
    //                 <div className="text-wrapper-10">
    //                   Can I send suggestions?
    //                 </div>
    //                 <p className="p-2">
    //                   <span className="text-wrapper-11">
    //                     For sure, we’d love to hear what you have to say. Just
    //                     hit us up at
    //                   </span>
    //                   <span className="text-wrapper-12">
    //                     {" "}
    //                     lambert@lambdaworks.io
    //                   </span>
    //                 </p>
    //               </div>
    //               <div className="frame-27">
    //                 <div className="text-wrapper-13">Is Lambert free?</div>
    //                 <p className="yes-for-now-at-least">
    //                   <span className="text-wrapper-14">
    //                     Yes! 🔥 For now at least.
    //                   </span>
    //                 </p>
    //               </div>
    //             </div>
    //             <img className="frame-28" src="/img/frame-1.svg" />
    //           </div>
    //         </div>
    //       </div>
    //     </div>
    //     <div className="frame-29">
    //       <p className="made-with-by">
    //         <span className="text-wrapper-11">Made with 🧡&nbsp;&nbsp;by </span>
    //         <span className="text-wrapper-12">LambdaWorks.io</span>
    //       </p>
    //       <div className="div-align-center">
    //         <p className="link-support">
    //           Support / Terms of Service / Privacy Policy
    //         </p>
    //       </div>
    //     </div>
    //   </div>
    // </div>
  );
};
