import React from "react";
import "../../style-policy.css";

export const PolicyDesktop = () => {
  return (
    <div className="frame">
      <img className="logo" alt="Logo" src="/img/logo.svg" />
      <div className="text-wrapper">Terms and Conditions & Privacy policy</div>
      <div className="section">
        <div className="div">
          <div className="text-wrapper-2">Terms and Conditions</div>
          <p className="p">Last updated: October 20, 2023</p>
          <p className="text-wrapper-3">
            Please read these terms and conditions carefully before using Our
            Service.
          </p>
        </div>
        <div className="div">
          <div className="text-wrapper-2">Interpretation and Definitions</div>
          <div className="div">
            <div className="text-wrapper-4">Interpretation</div>
            <p className="text-wrapper-3">
              The words of which the initial letter is capitalized have meanings
              defined under the following conditions. The following definitions
              shall have the same meaning regardless of whether they appear in
              singular or in plural.
            </p>
          </div>
          <div className="div">
            <div className="text-wrapper-4">Definitions</div>
            <p className="div-2">
              <span className="span">
                For the purposes of these Terms and Conditions:
                <br />
                <ul className="ul">
                <li>Affiliate means an entity that controls, is controlled by or is
                under common control with a party, where &#34;control&#34; means
                ownership of 50% or more of the shares, equity interest or other
                securities entitled to vote for election of directors or other
                managing authority.</li>
                <li>Company (referred to as either &#34;the Company&#34;,
                &#34;We&#34;, &#34;Us&#34; or &#34;Our&#34; in this Agreement)
                refers to LambdaWorks, Miše Dimitrijevića 12, Novi Sad.
                </li>
                <li>Country refers to: Serbia</li>
                <li>Service refers to the Website.</li>
                <li>Terms and Conditions (also referred as &#34;Terms&#34;) mean
                these Terms and Conditions that form the entire agreement
                between You and the Company regarding the use of the Service.
                </li>
                <li>Third-party Social Media Service means any services or content
                (including data, information, products or services) provided by
                a third-party that may be displayed, included or made available
                by the Service.
                </li>
                <br/>
                <li>Website refers to Lambert, accessible from
              <span className="text-wrapper-5">&nbsp;</span>
              <a
                href="http://lambertbot.com/"
                rel="noopener noreferrer"
                target="_blank"
              >
                <span className="text-wrapper-5">
                  lambertbot.com
                  <br />
                </span>
              </a></li>
              <li>
              <span className="span">
                You means the individual accessing or using the Service, or the
                company, or other legal entity on behalf of which such
                individual is accessing or using the Service, as applicable.
              </span>
              </li>
              </ul>

              </span>
            </p>
          </div>
        </div>
        <div className="div">
          <div className="text-wrapper-2">Acknowledgement</div>
          <p className="text-wrapper-3">
            These are the Terms and Conditions governing the use of this Service
            and the agreement that operates between You and the Company. These
            Terms and Conditions set out the rights and obligations of all users
            regarding the use of the Service. Your access to and use of the
            Service is conditioned on Your acceptance of and compliance with
            these Terms and Conditions. These Terms and Conditions apply to all
            visitors, users and others who access or use the Service. By
            accessing or using the Service You agree to be bound by these Terms
            and Conditions. If You disagree with any part of these Terms and
            Conditions then You may not access the Service.
            <br />
            Your access to and use of the Service is also conditioned on Your
            acceptance of and compliance with the Privacy Policy of the Company.
            Our Privacy Policy describes Our policies and procedures on the
            collection, use and disclosure of Your personal information when You
            use the Application or the Website and tells You about Your privacy
            rights and how the law protects You. Please read Our Privacy Policy
            carefully before using Our Service.
          </p>
        </div>
        <div className="div">
          <div className="text-wrapper-2">Links to Other Websites</div>
          <p className="text-wrapper-3">
            Our Service may contain links to third-party web sites or services
            that are not owned or controlled by the Company. The Company has no
            control over, and assumes no responsibility for, the content,
            privacy policies, or practices of any third party web sites or
            services. You further acknowledge and agree that the Company shall
            not be responsible or liable, directly or indirectly, for any damage
            or loss caused or alleged to be caused by or in connection with the
            use of or reliance on any such content, goods or services available
            on or through any such web sites or services. We strongly advise You
            to read the terms and conditions and privacy policies of any
            third-party web sites or services that You visit.
          </p>
        </div>
        <div className="div">
          <div className="text-wrapper-2">Termination</div>
          <p className="text-wrapper-3">
            We may terminate or suspend Your access immediately, without prior
            notice or liability, for any reason whatsoever, including without
            limitation if You breach these Terms and Conditions. Upon
            termination, Your right to use the Service will cease immediately.
          </p>
        </div>
        <div className="div">
          <div className="text-wrapper-2">Limitation of Liability</div>
          <p className="text-wrapper-3">
            Notwithstanding any damages that You might incur, the entire
            liability of the Company and any of its suppliers under any
            provision of this Terms and Your exclusive remedy for all of the
            foregoing shall be limited to the amount actually paid by You
            through the Service or 100 USD if You haven&#39;t purchased anything
            through the Service. To the maximum extent permitted by applicable
            law, in no event shall the Company or its suppliers be liable for
            any special, incidental, indirect, or consequential damages
            whatsoever (including, but not limited to, damages for loss of
            profits, loss of data or other information, for business
            interruption, for personal injury, loss of privacy arising out of or
            in any way related to the use of or inability to use the Service,
            third-party software and/or third-party hardware used with the
            Service, or otherwise in connection with any provision of this
            Terms), even if the Company or any supplier has been advised of the
            possibility of such damages and even if the remedy fails of its
            essential purpose. Some states do not allow the exclusion of implied
            warranties or limitation of liability for incidental or
            consequential damages, which means that some of the above
            limitations may not apply. In these states, each party&#39;s
            liability will be limited to the greatest extent permitted by law.
          </p>
        </div>
        <div className="div">
          <p className="text-wrapper-2">
            &#34;AS IS&#34; and &#34;AS AVAILABLE&#34; Disclaimer
          </p>
          <p className="text-wrapper-3">
            The Service is provided to You &#34;AS IS&#34; and &#34;AS
            AVAILABLE&#34; and with all faults and defects without warranty of
            any kind. To the maximum extent permitted under applicable law, the
            Company, on its own behalf and on behalf of its Affiliates and its
            and their respective licensors and service providers, expressly
            disclaims all warranties, whether express, implied, statutory or
            otherwise, with respect to the Service, including all implied
            warranties of merchantability, fitness for a particular purpose,
            title and non-infringement, and warranties that may arise out of
            course of dealing, course of performance, usage or trade practice.
            Without limitation to the foregoing, the Company provides no
            warranty or undertaking, and makes no representation of any kind
            that the Service will meet Your requirements, achieve any intended
            results, be compatible or work with any other software,
            applications, systems or services, operate without interruption,
            meet any performance or reliability standards or be error free or
            that any errors or defects can or will be corrected. Without
            limiting the foregoing, neither the Company nor any of the
            company&#39;s provider makes any representation or warranty of any
            kind, express or implied: (i) as to the operation or availability of
            the Service, or the information, content, and materials or products
            included thereon; (ii) that the Service will be uninterrupted or
            error-free; (iii) as to the accuracy, reliability, or currency of
            any information or content provided through the Service; or (iv)
            that the Service, its servers, the content, or e-mails sent from or
            on behalf of the Company are free of viruses, scripts, trojan
            horses, worms, malware, timebombs or other harmful components. Some
            jurisdictions do not allow the exclusion of certain types of
            warranties or limitations on applicable statutory rights of a
            consumer, so some or all of the above exclusions and limitations may
            not apply to You. But in such a case the exclusions and limitations
            set forth in this section shall be applied to the greatest extent
            enforceable under applicable law.
          </p>
        </div>
        <div className="div">
          <div className="text-wrapper-2">Governing Law</div>
          <p className="text-wrapper-3">
            The laws of the Country, excluding its conflicts of law rules, shall
            govern this Terms and Your use of the Service. Your use of the
            Application may also be subject to other local, state, national, or
            international laws.
          </p>
        </div>
        <div className="div">
          <div className="text-wrapper-2">Disputes Resolution</div>
          <p className="text-wrapper-3">
            If You have any concern or dispute about the Service, You agree to
            first try to resolve the dispute informally by contacting the
            Company.
          </p>
        </div>
        <div className="div">
          <p className="text-wrapper-2">For European Union (EU) Users</p>
          <p className="text-wrapper-3">
            If You are a European Union consumer, you will benefit from any
            mandatory provisions of the law of the country in which you are
            resident in.
          </p>
        </div>
        <div className="div">
          <div className="text-wrapper-2">United States Legal Compliance</div>
          <p className="text-wrapper-3">
            You represent and warrant that (i) You are not located in a country
            that is subject to the United States government embargo, or that has
            been designated by the United States government as a “terrorist
            supporting” country, and (ii) You are not listed on any United
            States government list of prohibited or restricted parties.
          </p>
        </div>
        <div className="div">
          <div className="text-wrapper-2">United States Legal Compliance</div>
          <p className="text-wrapper-3">
            You represent and warrant that (i) You are not located in a country
            that is subject to the United States government embargo, or that has
            been designated by the United States government as a “terrorist
            supporting” country, and (ii) You are not listed on any United
            States government list of prohibited or restricted parties.
          </p>
        </div>
        <div className="div">
          <div className="text-wrapper-2">Severability and Waiver</div>
          <div className="div">
            <div className="text-wrapper-4">Severability</div>
            <p className="text-wrapper-3">
              If any provision of these Terms is held to be unenforceable or
              invalid, such provision will be changed and interpreted to
              accomplish the objectives of such provision to the greatest extent
              possible under applicable law and the remaining provisions will
              continue in full force and effect.
            </p>
          </div>
          <div className="div">
            <div className="text-wrapper-4">Waiver</div>
            <p className="text-wrapper-3">
              Except as provided herein, the failure to exercise a right or to
              require performance of an obligation under this Terms shall not
              effect a party&#39;s ability to exercise such right or require
              such performance at any time thereafter nor shall be the waiver of
              a breach constitute a waiver of any subsequent breach.
            </p>
          </div>
        </div>
        <div className="div">
          <div className="text-wrapper-2">Translation Interpretation</div>
          <p className="text-wrapper-3">
            These Terms and Conditions may have been translated if We have made
            them available to You on our Service. You agree that the original
            English text shall prevail in the case of a dispute.
          </p>
        </div>
        <div className="div">
          <p className="text-wrapper-2">
            Changes to These Terms and Conditions
          </p>
          <p className="text-wrapper-3">
            We reserve the right, at Our sole discretion, to modify or replace
            these Terms at any time. If a revision is material We will make
            reasonable efforts to provide at least 30 days&#39; notice prior to
            any new terms taking effect. What constitutes a material change will
            be determined at Our sole discretion. By continuing to access or use
            Our Service after those revisions become effective, You agree to be
            bound by the revised terms. If You do not agree to the new terms, in
            whole or in part, please stop using the website and the Service.
          </p>
        </div>
        <div className="div">
          <div className="text-wrapper-2">Contact Us</div>
          <p className="div-2">
            <span className="span">
              If you have any questions about these Terms and Conditions, You
              can contact us:
              <br />
              By email:
            </span>
            <span className="text-wrapper-5"> office@lambdaworks.io</span>
          </p>
        </div>
        <div className="div">
          <div className="text-wrapper-2">Privacy Policy</div>
          <p className="div-2">
            <span className="span">
              Your privacy is important to us. It is LambdaWorks&#39; policy to
              respect your privacy regarding any information we may collect from
              you across our website,{" "}
            </span>
            <a
              href="https://lambertbot.com/"
              rel="noopener noreferrer"
              target="_blank"
            >
              <span className="text-wrapper-5">https://lambertbot.com</span>
            </a>
            <span className="text-wrapper-5">,</span>
            <span className="span">
              {" "}
              and other sites we own and operate. We only ask for personal
              information when we truly need it to provide a service to you. We
              collect it by fair and lawful means, with your knowledge and
              consent. 
              When you subscribe to our service, we may collect necessary 
              personal information, including your name, surname, birthday date, and 
              Slack ID. Your birthday date is exclusively used for our birthday command, 
              enhancing community engagement by announcing upcoming birthdays. Additionally, 
              your Slack ID serves various purposes, including facilitating our Secret Santa 
              command and swear command functionalities.  
              We only retain collected information for as long as necessary to provide 
              you with your requested service. What data we store, we’ll protect within 
              commercially acceptable means to prevent loss and theft, as well as unauthorized access,
              disclosure, copying, use or modification. We don’t share any
              personally identifying information publicly or with third-parties,
              except when required to by law. Our website may link to external
              sites that are not operated by us. Please be aware that we have no
              control over the content and practices of these sites, and cannot
              accept responsibility or liability for their respective privacy
              policies. You are free to refuse our request for your personal
              information, with the understanding that we may be unable to
              provide you with some of your desired services. Your continued use
              of our website will be regarded as acceptance of our practices
              around privacy and personal information. If you have any questions
              about how we handle user data and personal information, feel free
              to contact us. This policy is effective as of 28 January 2020.
            </span>
          </p>
        </div>
        <div className="div">
          <div className="text-wrapper-2">Data Retention Policy</div>
          <p className="text-wrapper-3">
            LambdaWorks seeks to ensure that it retains only data necessary to
            effectively conduct its program activities and work in fulfillment
            of its mission. The need to retain data varies widely with the type
            of data and the purpose for which it was collected. LambdaWorks
            strives to ensure that data is only retained for the period
            necessary to fulfill the purpose for which it was collected and is
            fully deleted when no longer required. This policy sets forth
            LambdaWorks’s guidelines on data retention and is to be consistently
            applied throughout the organization.
          </p>
        </div>
        <div className="div">
          <div className="text-wrapper-2">Scope</div>
          <p className="text-wrapper-3">
            This policy covers all data collected by LambdaWorks and stored on
            LambdaWorks owned or leased systems and media, regardless of
            location. It applies to both data collected and held electronically
            (including photographs, video and audio recordings) and data that is
            collected and held as hard copy or paper files. The need to retain
            certain information may be mandated by federal or local law, federal
            regulations and legitimate business purposes, as well as the EU
            General Data Protection Regulation (GDPR).
          </p>
        </div>
        <div className="div">
          <div className="text-wrapper-2">Reasons for Data Retention</div>
          <p className="text-wrapper-3">
            LambdaWorks retains only that data that is necessary to effectively
            conduct its program activities, fulfill its mission and comply with
            applicable laws and regulations. Reasons for data retention include:
            <ul className="ul">
            <li>Providing an ongoing service to the data subject (e.g. sending a
            newsletter, publication or ongoing program updates to an individual,
            ongoing training or participation in LambdaWorks’s programs,
            processing of employee payroll and other benefits)
            </li>
            <li>Compliance with applicable laws and regulations associated with
            financial and programmatic reporting by LambdaWorks to its funding
            agencies and other donors
            </li>
            <li>Compliance with applicable labor, tax and immigration laws</li>
            <li>Other regulatory requirements</li>
            <li>Security incident or other investigation</li>
            <li>Intellectual property preservation</li>
            <li>Litigation</li>
            </ul>
          </p>
        </div>
        <div className="div">
          <div className="text-wrapper-2">Data Duplication</div>
          <p className="text-wrapper-3">
            LambdaWorks seeks to avoid duplication in data storage whenever
            possible, though there may be instances in which for programmatic or
            other business reasons it is necessary for data to be held in more
            than one place. This policy applies to all data in LambdaWorks’
            possession, including duplicate copies of data.
          </p>
        </div>
        <div className="div">
          <div className="text-wrapper-2">Retention Requirements</div>
          <p className="text-wrapper-3">
            LambdaWorks has set the following guidelines for retaining all
            personal data as defined in the Institute’s data privacy policy.
            <br />
            Website visitor data will be retained as long as necessary to
            provide the service requested/initiated through the LambdaWorks’
            website.
            <br />
            Contributor data will be retained for the year in which the
            individual has contributed and then for one month after the date of
            the last contribution.
            <br />
            Financial information will not be retained longer than is necessary
            to process a single transaction.
            <br />
            Event participant data will be retained for the period of the event,
            including any follow up activities, such as the distribution of
            reports, plus a period of one month.
            <br />
            Program participant data (including sign in sheets) will be retained
            for the duration of the grant agreement that financed the program
            plus any additional time required under the terms of the grant
            agreement.
            <br />
            Personal data of subgrantees, subcontractors and vendors will be
            kept for the duration of the contract or agreement.
            <br />
            Employee data will be held for the duration of employment and then
            one month after the last day of employment. Data associated with
            employee wages, leave and pension shall be held for the period of
            employment plus one month, with the exception of pension eligibility
            and retirement beneficiary data which shall be kept for one month.
            <br />
            Recruitment data, including interview notes of unsuccessful
            applicants, will be held for one month after the closing of the
            position recruitment process.
            <br />
            Consultant (both paid and pro bono) data will be held for the
            duration of the consulting contract plus one month after the end of
            the consultancy.
            <br />
            Board member data will be held for the duration of service on the
            Board plus for one month after the end of the member’s term.
            <br />
            Data associated with tax payments (including payroll, corporate and
            VAT) will be held for one month.
            <br />
            Operational data related to program proposals, reporting and program
            management will be held for the period required by the LambdaWorks
            donor, but not more than one month.
          </p>
        </div>
        <div className="div">
          <div className="text-wrapper-2">Data Destruction</div>
          <p className="text-wrapper-3">
            Data destruction ensures that LambdaWorks manages the data it
            controls and processes it in an efficient and responsible manner.
            When the retention period for the data as outlined above expires,
            LambdaWorks will actively destroy the data covered by this policy.
            If an individual believes that there exists a legitimate business
            reason why certain data should not be destroyed at the end of a
            retention period, he or she should identify this data to his/her
            supervisor and provide information as to why the data should not be
            destroyed. Any exceptions to this data retention policy must be
            approved by LambdaWorks’s data protection offer in consultation with
            legal counsel. In rare circumstances, a litigation hold may be
            issued by legal counsel prohibiting the destruction of certain
            documents. A litigation hold remains in effect until released by
            legal counsel and prohibits the destruction of data subject to the
            hold.
          </p>
        </div>
        <div className="div">
          <div className="text-wrapper-2">User Requests</div>
          <p className="div-2">
            <span className="span">
              The user may ask to have their data deleted, to access their data,
              or to transfer their data by contacting the development team: by
              sending an email to{" "}
            </span>
            <span className="text-wrapper-5">lambert@lambdaworks.io.</span>
          </p>
        </div>
      </div>
      <div className="div-3">
        <p className="made-with-by">
          <span className="text-wrapper-6">Made with 🧡&nbsp;&nbsp;by </span>
          <a href="https://lambdaworks.io" className="text-wrapper-7">
              LambdaWorks.io
          </a>
        </p>
        <div className="div-align-center">
            <p className="link-support">
              <a href="/support" className="text-wrapper-7">
                Support
              </a>{" "}
              /{" "}
              <a href="/policy" className="text-wrapper-7">
                Terms and Conditions & Privacy Policy
              </a>
            </p>
          </div>
      </div>
    </div>
  );
};
