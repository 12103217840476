import React from "react";
import "../../style-mobile.css";

export const SupportMobile = () => {
  return (
    <div className="lambert-mobile">
      {/* <img className="frame"   src="/img-mobile/frame.svg" />
      <img className="img"   src="/img-mobile/frame-1.svg" />
      <img className="frame-2"   src="/img-mobile/frame-2.svg" />
      <img className="frame-3"   src="/img-mobile/frame-3.svg" />
      <img className="frame-4"   src="/img-mobile/frame-4.svg" />
      <img className="frame-5"   src="/img-mobile/frame-5.svg" />
      <img className="frame-6"   src="/img-mobile/frame-6.svg" />
      <img className="frame-7"   src="/img-mobile/frame-7.svg" />
      <img className="frame-8"   src="/img-mobile/frame-8.svg" />
      <img className="frame-9"   src="/img-mobile/frame-9.svg" />
      <img className="frame-10"   src="/img-mobile/frame-10.svg" />
      <img className="frame-11"   src="/img-mobile/frame-11.svg" />
      <img className="frame-12"   src="/img-mobile/frame-12.svg" />
      <img className="frame-13"   src="/img-mobile/frame-13.svg" />
      <img className="frame-14"   src="/img-mobile/frame-14.svg" />
      <img className="frame-15"   src="/img-mobile/frame-15.svg" />
      <img className="frame-16"   src="/img-mobile/frame-16.svg" /> */}
      <div className="div">
        <div className="frame-17">
          <div className="overlap-wrapper">
            <div className="overlap">
              <h1 className="text-wrapper">Support</h1>
              <div className="overlap-group-wrapper">
                <div className="overlap-group">
                  <div className="ellipse" />
                  <img className="vector" src="/img-mobile/vector.svg" />
                  <img className="vector-2" src="/img-mobile/vector-1.svg" />
                  <img className="vector-3" src="/img-mobile/vector-2.svg" />
                  <img className="vector-4" src="/img-mobile/vector-3.svg" />
                  <img className="group" src="/img-mobile/group.png" />
                  <img className="vector-5" src="/img-mobile/vector-4.svg" />
                  <img className="group-2" src="/img-mobile/group-1.png" />
                  <img className="group-3" src="/img-mobile/group-2.png" />
                  <img className="group-4" src="/img-mobile/group-3.png" />
                  <img className="vector-6" src="/img-mobile/vector-5.svg" />
                  <img className="vector-7" src="/img-mobile/vector-6.svg" />
                  <img className="group-5" src="/img-mobile/group-4.png" />
                  <img className="vector-8" src="/img-mobile/vector-7.svg" />
                  <img className="vector-9" src="/img-mobile/vector-8.svg" />
                  <img className="vector-10" src="/img-mobile/vector-9.svg" />
                  <img className="vector-11" src="/img-mobile/vector-10.svg" />
                  <img className="vector-12" src="/img-mobile/vector-11.svg" />
                  <img className="vector-13" src="/img-mobile/vector-12.svg" />
                  <img className="vector-14" src="/img-mobile/vector-13.svg" />
                  <img className="vector-15" src="/img-mobile/vector-14.svg" />
                  <img className="vector-16" src="/img-mobile/vector-15.svg" />
                  <img className="vector-17" src="/img-mobile/vector-16.svg" />
                  <img className="vector-18" src="/img-mobile/vector-17.svg" />
                  <img className="vector-19" src="/img-mobile/vector-18.svg" />
                  <img className="vector-20" src="/img-mobile/vector-19.svg" />
                  <img className="vector-21" src="/img-mobile/vector-20.svg" />
                  <img className="vector-22" src="/img-mobile/vector-21.svg" />
                  <img className="vector-23" src="/img-mobile/vector-22.svg" />
                  <img className="vector-24" src="/img-mobile/vector-23.svg" />
                  <img className="vector-25" src="/img-mobile/vector-24.svg" />
                  <img className="vector-26" src="/img-mobile/vector-25.svg" />
                  <img className="vector-27" src="/img-mobile/vector-26.svg" />
                  <img className="vector-28" src="/img-mobile/vector-27.svg" />
                  <img className="vector-29" src="/img-mobile/vector-28.svg" />
                  <img className="vector-30" src="/img-mobile/vector-29.svg" />
                  <img className="vector-31" src="/img-mobile/vector-30.svg" />
                  <img className="vector-32" src="/img-mobile/vector-31.svg" />
                  <img className="vector-33" src="/img-mobile/vector-32.svg" />
                  <img className="vector-34" src="/img-mobile/vector-33.svg" />
                  <img className="vector-35" src="/img-mobile/vector-34.svg" />
                  <img className="group-6" src="/img-mobile/group-5.png" />
                </div>
              </div>
            </div>
          </div>
          <p className="meet-lambert-your">
            <span className="span">
              For support you can reach us at our email
            </span>
          </p>
          <p className="lambert-is-an-office">⏩ lambert@lambdaworks.io ⏪</p>
        </div>
      </div>
      <div className="frame-18">
        <img className="frame-19" src="/img-mobile/frame-17.svg" />
        <div className="frame-20">
          <div className="text-wrapper-2">Multiple features</div>
          <div className="frame-21">
            <p className="track-debts-between">
              <span className="text-wrapper-3">
                🏦&nbsp;&nbsp; Track debts between colleagues
              </span>
            </p>
            <div className="div-2">
              <span className="text-wrapper-3">🤐&nbsp;&nbsp;Swear jar</span>
            </div>
            <p className="div-2">
              🎲&nbsp;&nbsp;Create poll,&nbsp;&nbsp;roll, or pick random
            </p>
            <div className="div-2">
              <span className="text-wrapper-3">
                🎂&nbsp;&nbsp;Birthday notifications
              </span>
            </div>
            <p className="search-and-deliver">
              <span className="text-wrapper-3">
                📷&nbsp;&nbsp;Search and deliver random images or videos - for
                fun
              </span>
            </p>
            <div className="div-2">🎁&nbsp;&nbsp;Secret santa</div>
          </div>
        </div>
      </div>
      <div className="frame-22">
        <img className="frame-23" src="/img-mobile/frame-18.svg" />
        <div className="frame-24">
          <div className="text-wrapper-2">Settle debts</div>
          <p className="p">
            Never lose track of office debts. <br />
            Open tabs, track and settle debts all within Slack.
          </p>
        </div>
        <img className="image" src="/img-mobile/image-10.png" />
      </div>
      <div className="frame-24">
        <img className="export-this" src="/img-mobile/export-this.svg" />
        <div className="frame-24">
          <div className="text-wrapper-2">Swear jar</div>
          <p className="pay-for-your-swears">
            Pay for your swears&nbsp;and improve the office budget.
          </p>
        </div>
        <div className="frame-25">
          <div className="div-wrapper">
            <div className="overlap-group-2">
              <img className="rectangle" src="/img-mobile/rectangle-17.png" />
              <div className="text-wrapper-4">@Lambert_slack</div>
            </div>
          </div>
          <img
            className="stats-no-swears"
            src="/img-mobile/stats-no-swears-1.png"
          />
        </div>
      </div>
      <div className="frame-24">
        <img className="frame-26" src="/img-mobile/frame-19.svg" />
        <p className="heading-settle">
          Create polls,&nbsp;roll, or pick random
        </p>
        <p className="p">
          End indecision about lunch, suggest team building activities? <br />
          Resolve disagreement once and for all.
        </p>
        <div className="frame-27">
          <img className="image-2" src="/img-mobile/image-11.png" />
          <div className="frame-wrapper">
            <div className="frame-28">
              <div className="frame-29">
                <div className="overlap-group-3">
                  <img
                    className="rectangle-2"
                    src="/img-mobile/rectangle-17-1.png"
                  />
                  <div className="text-wrapper-5">@Lambert_slack</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="frame-20">
        <div className="frame-20">
          <img className="frame-30" src="/img-mobile/frame-20.svg" />
          <div className="heading-create">Secret Santa</div>
          <p className="pay-for-your-swears">
            Pay for your swears&nbsp;and improve the office budget.
          </p>
        </div>
        <div className="frame-31">
          <div className="overlap-group-4">
            <div className="rectangle-3" />
            <div className="text-wrapper-6">@Lambert_slack</div>
          </div>
        </div>
      </div>
      <div className="frame-32">
        <p className="text-wrapper-7">Add Lambert to your workspace now!</p>
        <img
          className="link-add-to-slack"
          alt="Link add to slack"
          src="/img-mobile/link-add-to-slack-png-1.png"
        />
      </div>
      <div className="frame-33">
        <div className="frame-34">
          <div className="frame-35">
            <div className="frame-36">
              <div className="text-wrapper-8">FAQ</div>
              <div className="frame-37">
                <div className="frame-21">
                  <p className="text-wrapper-9">
                    How do I install Lambert on my Slack workspace?
                  </p>
                  <p className="it-s-really-simple">
                    It&#39;s really simple, justclick&nbsp; 
                    <a href="https://prod.lambertbot.com/auth" onClick={addToSlack} className="text-wrapper-12">
                      Add to slack
                    </a> and follow
                    the instructions.
                  </p>
                </div>
                <div className="frame-21">
                  <p className="text-wrapper-9">
                    Where can I get help if this app doesn’t work?
                  </p>
                  <p className="p-2">
                    <span className="text-wrapper-10">Reach out to us at </span>
                    <span className="text-wrapper-11">
                      lambert@lambdaworks.io
                    </span>
                    <span className="text-wrapper-10">
                      {" "}
                      and we&#39;ll help you out as soon as we can.
                    </span>
                  </p>
                </div>
                <div className="frame-21">
                  <div className="text-wrapper-9">Can I send suggestions?</div>
                  <p className="p-2">
                    <span className="text-wrapper-10">
                      For sure, we’d love to hear what you have to say. Just hit
                      us up at
                    </span>
                    <span className="text-wrapper-11">
                      {" "}
                      lambert@lambdaworks.io
                    </span>
                  </p>
                </div>
              </div>
            </div>
            <img className="frame-39" src="/img-mobile/frame-25.svg" />
          </div>
        </div>
        <div className="frame-40">
          <p className="made-with-by">
            <span className="text-wrapper-10">Made with 🧡&nbsp;&nbsp;by </span>
            <span className="text-wrapper-11">LambdaWorks.io</span>
          </p>
          <div className="div-align-center">
            <p className="link-support">
              Support / Terms of Service / Privacy Policy
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
