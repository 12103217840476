import React from "react";
import ReactDOMClient from "react-dom/client";
import { Confirmation } from "./screens/Confirmation";
import { Policy } from "./screens/Policy";
import { createBrowserRouter, RouterProvider, Route } from "react-router-dom";
import { Support } from "./screens/Support/Support";
import { Landing } from "./screens/Landing/Landing";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Landing />,
  },
  {
    path: "/confirmation",
    element: <Confirmation />,
  },
  {
    path: "/policy",
    element: <Policy />,
  },
  {
    path: "/support",
    element: <Support />,
  },
]);

const app = document.getElementById("app");
const root = ReactDOMClient.createRoot(app);
root.render(<RouterProvider router={router} />);
