import React from "react";
import Amplitude from "amplitude";
import "../../style-desktop.css";

export const LandingDesktop = () => {
  const AMPLITUDE_API_KEY = "43a4df50762183097d983a4f570637f0";
  const amplitude = new Amplitude(AMPLITUDE_API_KEY);
  const initData = {
    event_type: "landing page visit",
    user_id: "client",
    event_properties: {},
  };
  console.log({ initData: initData });
  amplitude.track(initData);

  const addToSlack = () => {
    const data = {
      event_type: "add to slack click",
      user_id: "client",
      event_properties: {},
    };
    console.log({ data: data });
    amplitude.track(data);
  };
  return (
    <div className="lambert">
      <a href="https://www.producthunt.com/posts/lambert?utm_source=producthunt&utm_medium=lw-website&utm_campaign=lambert">
        <img
          src="https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=420761&theme=light"
          alt="Lambert - Office&#0032;utility&#0032;Slack&#0032;bot&#0032;making&#0032;office&#0032;life&#0032;a&#0032;bit&#0032;more&#0032;fun | Product Hunt"
          class="product-hunt"
        />
      </a>
      <img className="frame" src="/img/frame.svg" />
      <div className="div">
        <div className="frame-2">
          <div className="group">
            {/* <h1 className="text-wrapper">Lambert</h1> */}
            <div className="overlap-group-wrapper">
              <div className="overlap-group">
                <a href="/">
                  <img src="/img/logo.svg" />
                </a>
              </div>
            </div>
          </div>
          <p className="meet-lambert-your">
            <span className="span">Meet Lambert, your office botler 🎩 </span>
          </p>
          <p className="lambert-is-an-office">
            Lambert is an office utility bot that will take care of
            <br />
            loose ends in the most common office matters.
          </p>
        </div>
        <a
          class="mt-60"
          href="https://prod.lambertbot.com/auth"
          onClick={addToSlack}
        >
          <img className="link-add-to-slack" src="/img/add_to_slack.png" />
        </a>
      </div>
      <div className="frame-3">
        <img className="frame-6" src="/img/frame-6.svg" />
        <div className="frame-multiple-features">
          <div className="text-wrapper-2">Multiple features</div>
          <div className="frame-5">
            <p className="track-debts-between">
              <span className="text-wrapper-3">
                🏦&nbsp;&nbsp; Track debts between colleagues
              </span>
            </p>
            <div className="span-wrapper">
              <span className="text-wrapper-3">🤐&nbsp;&nbsp;Swear jar</span>
            </div>
            <p className="text-wrapper-3">
              🎲&nbsp;&nbsp;Create poll,&nbsp;roll, or pick random
            </p>
            <div className="span-wrapper">
              <span className="text-wrapper-3">
                🎂&nbsp;&nbsp;Birthday notifications
              </span>
            </div>
            <p className="text-wrapper-3">
              📷&nbsp;&nbsp;Search and deliver random images or videos - for fun
            </p>
            <div className="text-wrapper-3">🎁&nbsp;&nbsp;Secret Santa</div>
          </div>
        </div>
      </div>
      <div className="frame-7">
        <img className="open-debt-png" src="/img/open-debt-png.png" />
        <div className="frame-8">
          <div className="bank-header-text">
            <div className="bank-header">Settle debts</div>
            <p className="bank-text">
              Never lose track of office debts. <br />
              Open tabs, track and settle debts all within Slack.
            </p>
          </div>
          <img className="frame-9" src="/img/frame-5.svg" />
        </div>
      </div>
      <div className="frame-swear-1">
        <div className="frame-swear-2">
          <div className="swear-header-text">
            <div className="text-wrapper-2">Swear jar</div>
            <p className="pay-for-your-swears">
              Pay for your swears&nbsp;and improve the office budget.
            </p>
          </div>
          <div className="overlap-wrapper">
            <div className="overlap">
              <div className="overlap-group-2">
                <img
                  className="overlap-group-swear-jar"
                  src="/img/swear-jar.svg"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="frame-swear-3">
          <img className="stats-no-swears" src="/img/new-1.png" />
          <img className="stats-no-swears" src="/img/stats-no-swears-1.png" />
        </div>
      </div>
      <div className="frame-poll-1">
        <div className="frame-poll-2">
          <div className="poll-examples">
            <div className="overlap-group-4">
              <img className="poll" src="/img/poll2-1.png" />
              <img className="roll" src="/img/roll3-1.png" />
            </div>
          </div>
        </div>
        <div className="frame-poll-3">
          <div className="frame-poll-header-text">
            <p className="heading-settle">
              Create polls,&nbsp;roll, or pick random
            </p>
            <p className="poll-text">
              End indecision about lunch, suggest team building activities?
              <br></br>
              Resolve disagreement once and for all.
            </p>
          </div>
          <div className="frame-18">
            <div className="overlap-3">
              <div className="overlap-group-5">
                <img src="/img/pick-roll.svg" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="frame-santa-1">
        <div className="frame-santa-2">
          <div className="santa-header-text">
            <div className="text-wrapper-2">Secret Santa</div>
            <p className="pay-for-your-swears">
              'Tis the season to be jolly, boost the holiday spirit by
              <br /> organizing Secret 🎅 with your team. <br />
              Fa la la la la... 🎁
            </p>
          </div>
          <img className="santa-svg" src="/img/frame-2.svg" />
        </div>
        <div className="frame-20">
          <img className="secret-santa" src="/img/answer-1.png" />
        </div>
      </div>
      <div className="div">
        <p className="text-wrapper-9">Add Lambert to your workspace now!</p>
        <a
          class="mt-60"
          href="https://prod.lambertbot.com/auth"
          onClick={addToSlack}
        >
          <img className="link-add-to-slack" src="/img/add_to_slack.png" />
        </a>
      </div>
      <div className="frame-21">
        <div className="section">
          <div className="frame-22">
            <div className="text-wrapper-9">FAQ</div>
            <div className="frame-23">
              <div className="frame-24">
                <div className="frame-14">
                  <p className="text-wrapper-10">
                    How do I install Lambert on my Slack workspace?
                  </p>
                  <p className="p">
                    It&#39;s really simple, just click&nbsp; 
                    <a href="https://prod.lambertbot.com/auth" onClick={addToSlack} className="text-wrapper-12">
                      Add to slack
                    </a> and follow
                    the instructions.
                  </p>
                </div>
                <div className="frame-14">
                  <p className="text-wrapper-10">
                    Where can I get help if this app doesn’t work?
                  </p>
                  <p className="p-2">
                    <span className="text-wrapper-11">Reach out to us at </span>
                    <a
                      href="mailto:lambert@lambdaworks.io"
                      className="text-wrapper-12"
                    >
                      lambert@lambdaworks.io
                    </a>
                    <span className="text-wrapper-11">
                      {" "}
                      and we&#39;ll help you out as soon as we can.
                    </span>
                  </p>
                </div>
              </div>
              <div className="frame-25">
                <div className="frame-21">
                  <div className="frame-26">
                    <div className="text-wrapper-10">
                      Can I send suggestions?
                    </div>
                    <p className="p-2">
                      <span className="text-wrapper-11">
                        For sure, we’d love to hear what you have to say. Just
                        hit us up at
                      </span>
                      <a
                        href="mailto:lambert@lambdaworks.io"
                        className="text-wrapper-12"
                      >
                        {" "}
                        lambert@lambdaworks.io
                      </a>
                    </p>
                  </div>
                </div>
                <img className="frame-28" src="/img/frame-1.svg" />
              </div>
            </div>
          </div>
        </div>
        <div className="frame-29">
          <p className="made-with-by">
            <span className="text-wrapper-11">Made with 🧡&nbsp;&nbsp;by </span>
            <a href="https://lambdaworks.io" className="text-wrapper-12">
              LambdaWorks.io
            </a>
          </p>
          <div className="div-align-center">
            <p className="link-support">
              <a href="/support" className="link-support">
                Support
              </a>{" "}
              /{" "}
              <a href="/policy" className="link-support">
                Terms and Conditions & Privacy Policy
              </a>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
